import { TabContext, TabList, TabPanel } from '@mui/lab'
import { Box, Tab } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { ERROR_PAGE } from '../../../navigation/routes'
import axiosInstance from '../../../services/axios/axios'
import GithubUsersTable from '../../components/admin/GithubUsersTable'
import RippleLoader from '../../components/loader/RippleLoader'
import ProjectTable from '../../components/recruiter-dashboard/dashboard-table/ProjectCardList'
import ApplicantAdditionTable from '../../components/self-jobs/ApplicantAdditionTable'
import SuperAdminUserTable from '../../components/super-admin/SuperAdminUserTable'
import RecruiterJobsCardList from '../../components/recruiter-dashboard/dashboard-table/RecruiterJobsCardList'

const AdminActivity = () => {
  const navigate = useNavigate()
  const { user } = useSelector((state) => state.auth)
  const [activityData, setActivityData] = useState({})
  const [loading, setLoading] = useState(true)
  const [value, setValue] = useState('1')

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  const fetchAdminActivity = async () => {
    try {
      const { data } = await axiosInstance(
        `https://to5ycnuhg9.execute-api.ap-south-1.amazonaws.com/Dev/adminGetRecentData?n=7`
      )
      console.log(data)
      if (data.message) {
        setActivityData(data.message)
      }
      setLoading(false)
    } catch (err) {
      console.log(err.message)
    }
  }

  useEffect(() => {
    if (user.type !== 'SUPER_ADMIN') {
      navigate(ERROR_PAGE)
      return
    }
    fetchAdminActivity()
  }, [])

  return (
    <div className="min-h-screen p-6 md:p-10">
      {loading ? (
        <RippleLoader />
      ) : (
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              <Tab label="Jobs" value="1" />
              <Tab label="Projects" value="2" />
              <Tab label="Users" value="3" />
              <Tab label="Applicants" value="4" />
              <Tab label="Github Users" value="5" />
            </TabList>
          </Box>
          <TabPanel value="1">
            <RecruiterJobsCardList jobData={activityData?.jobs ?? []} />
          </TabPanel>
          <TabPanel value="2">
            <ProjectTable projectData={activityData?.projects ?? []} />
          </TabPanel>
          <TabPanel value="3">
            <SuperAdminUserTable data={activityData?.users ?? []} />
          </TabPanel>
          <TabPanel value="4">
            <ApplicantAdditionTable
              applicantsData={activityData?.applicant ?? []}
              isAdmin={true}
            />
          </TabPanel>
          <TabPanel value="5">
            <GithubUsersTable githubUsers={activityData?.github_users ?? []} />
          </TabPanel>
        </TabContext>
      )}
    </div>
  )
}

export default AdminActivity
